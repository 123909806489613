import { Provider } from 'react-redux';
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom';

import { main, store } from './configuration';

import { config } from './config';
import AppContainer from './features/app/App.container';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import { GoogleTagManagerUtil } from './features/app/utils/googleTagManager';
import DefaultRedirect from './features/app/DefaultRoute';
import { createRoot } from 'react-dom/client';
import handleLoginRedirect from './configuration/login/redirect';

const renderApplication = () => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <HashRouter>
                    <Routes>
                        <Route path="*" element={<DefaultRedirect />} />
                        <Route path="/tags" element={<AppContainer />} />
                        <Route path="/tags/:id" element={<AppContainer />} />
                    </Routes>
                    <GoogleTagListener />
                </HashRouter>
            </Provider>
        </ErrorBoundary>
    );
};

export const gtmUtil = new GoogleTagManagerUtil(window);
function GoogleTagListener() {
    const location = useLocation();
    return <>{gtmUtil.triggerPageViewFromLocation(location)}</>;
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
