import {
    TAG_CREATION_DIALOG_CLOSE,
    TAG_CREATION_DIALOG_OPEN,
    TAG_CREATION_FAILED,
    TAG_CREATION_FINISHED,
    TAG_CREATION_STARTED,
    TAG_CREATION_SUCCESSFUL,
    TagCreationDialogActions,
} from './TagCreationDialog.types';

export interface TagCreationDialogState {
    isTagCreationDialogOpen: boolean;
    tagCreating: boolean;
    tagCreationSuccessful: boolean;
    tagCreationFailed: boolean;
    lastErrorCode: string | null;
}

export const INITIAL_CREATION_DIALOG_STATE: TagCreationDialogState = {
    isTagCreationDialogOpen: false,
    tagCreating: false,
    tagCreationSuccessful: false,
    tagCreationFailed: false,
    lastErrorCode: null,
};

export const tagCreationDialogReducer = (
    state: TagCreationDialogState = INITIAL_CREATION_DIALOG_STATE,
    action: TagCreationDialogActions
): TagCreationDialogState => {
    switch (action.type) {
        case TAG_CREATION_DIALOG_OPEN:
            return {
                ...state,
                isTagCreationDialogOpen: true,
            };
        case TAG_CREATION_DIALOG_CLOSE:
            return {
                ...state,
                isTagCreationDialogOpen: false,
            };
        case TAG_CREATION_STARTED:
            return {
                ...state,
                tagCreating: true,
            };
        case TAG_CREATION_SUCCESSFUL:
            return {
                ...state,
                tagCreating: false,
                tagCreationSuccessful: true,
                isTagCreationDialogOpen: false,
            };
        case TAG_CREATION_FAILED:
            return {
                ...state,
                tagCreating: false,
                tagCreationFailed: true,
                lastErrorCode: action.payload.errorCode,
            };
        case TAG_CREATION_FINISHED:
            return {
                ...state,
                tagCreating: false,
                tagCreationSuccessful: false,
                tagCreationFailed: false,
                lastErrorCode: null,
            };
        default:
            return state;
    }
};
