import { RootState } from '../../../../configuration/setup/store';

export function getShowDialog(state: RootState): boolean {
    return state.tagCreationDialog.isTagCreationDialogOpen;
}

export function getTagCreating(state: RootState): boolean {
    return state.tagCreationDialog.tagCreating;
}

export function getTagCreationSuccessful(state: RootState): boolean {
    return state.tagCreationDialog.tagCreationSuccessful;
}

export function getTagCreationFailed(state: RootState): boolean {
    return state.tagCreationDialog.tagCreationFailed;
}

export function getLastErrorCode(state: RootState): string | null {
    return state.tagCreationDialog.lastErrorCode;
}
