import { TagListToolbarPropertiesFromDispatch } from './TagListToolbar.container';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { FormattedMessage } from 'react-intl';
import TagCreationDialogContainer from '../../create/TagCreationDialog.container';

type TagListToolbarProperties = TagListToolbarPropertiesFromDispatch;

export const TagListToolbar = (props: TagListToolbarProperties) => {
    const { openTagCreationDialog } = props;

    return (
        <div id="TagsTableToolbar">
            <TableToolbar>
                <div className="table-toolbar-container">
                    <div className="table-toolbar-group-left">
                        <div className="table-toolbar-column">
                            <button
                                className="btn btn-primary"
                                type="button"
                                id="addTag"
                                data-cy="add-tag"
                                onClick={openTagCreationDialog}
                            >
                                <span className="rioglyph rioglyph-plus" />
                                <FormattedMessage id={'tags.tagListToolbar.addButton'} />
                            </button>
                        </div>
                        <TagCreationDialogContainer />
                    </div>
                    <div className="table-toolbar-group-right">
                        <div className="table-toolbar-column">
                            <div className="btn-toolbar">
                                <TableViewToggles onViewTypeChange={props.handleViewTypeChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </TableToolbar>
        </div>
    );
};
