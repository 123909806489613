import { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { connect } from 'react-redux';
import { TagListToolbarThunkDispatch, tagsTableViewTypeChanges } from './TagListToolbar.actions';
import { TagListToolbar } from './TagListToolbar';
import { openTagCreationDialog } from '../../create/TagCreationDialog.actions';
import { TagCreationDialogThunkDispatch } from '../../create/TagCreationDialog.types';

export interface TagListToolbarPropertiesFromDispatch {
    handleViewTypeChange: (viewType: TableViewTogglesViewType) => void;
    openTagCreationDialog: () => void;
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(
    dispatch: TagListToolbarThunkDispatch & TagCreationDialogThunkDispatch
): TagListToolbarPropertiesFromDispatch {
    return {
        handleViewTypeChange: (viewType: TableViewTogglesViewType) => dispatch(tagsTableViewTypeChanges(viewType)),
        openTagCreationDialog: () => dispatch(openTagCreationDialog()),
    };
}

const TagListToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(TagListToolbar);

export default TagListToolbarContainer;
