import { config } from '../../config';
import { trace } from './trace';
import { routeStorage } from '../login/storage';
import { reportErrorToSentry } from './sentry';
import { UserManager } from 'oidc-client-ts';

const param = (window: Window, regex: RegExp, defaultValue = null) => {
    let result = defaultValue;
    decodeURI(window.location.href).replace(regex, (substring, it) => {
        result = it;
        return substring;
    });
    return result;
};

const saveCurrentRoute = () => {
    const initialRoute = '/' + window.location.hash;

    routeStorage.saveRoute(initialRoute);

    trace('saving initial route', initialRoute);
};

export const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = Boolean(param(window, /.*code=([^&]+)/u));

    try {
        const user = await userManager.signinSilent();
        const initialRoute = routeStorage.getRoute();

        trace('initialRoute lookup', initialRoute);

        if (initialRoute && isFreshRedirect && !redirectForDeprecatedLocation(initialRoute)) {
            trace(`Go to location "${initialRoute}"`);
            window.history.replaceState({}, '', initialRoute);
        }

        routeStorage.discardRoute();
        return user;
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);

        if (config.login.preventRedirect) {
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else if (isFreshRedirect) {
            trace('oidc.signinSilent.error', 'redirect prevented due to suspicious signin error', error);
            routeStorage.discardRoute();
            reportErrorToSentry(error);
        } else {
            saveCurrentRoute();
            await userManager.signinRedirect();
        }

        trace('auth problem?', error);
        throw new Error('Need to sign in');
    }
};

const redirectForDeprecatedLocation = (initialRoute: string | null) => {
    if (initialRoute && initialRoute.indexOf('/#tags') >= 0) {
        const newRoute = '/';
        trace(`Deprecated url loaded. Redirecting to "${newRoute}"`);
        window.history.replaceState({}, '', newRoute);
        return true;
    }
    return false;
};
