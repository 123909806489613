import { FormattedMessage } from 'react-intl';
import { groupsInfoContent } from './GroupsInfo';
import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';

export interface GroupsInfoDialogProps {
    show: boolean;
    onClose: () => void;
}

export const GroupsInfoDialog = (props: GroupsInfoDialogProps) => {
    const { show, onClose } = props;

    return (
        <SimpleDialog
            show={show}
            title={<FormattedMessage id={'tags.groupsInfo.title'} />}
            content={groupsInfoContent()}
            onClose={onClose}
            bsSize={'sm'}
        />
    );
};
