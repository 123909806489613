import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { FormattedMessage } from 'react-intl';
import { PopoverLinkItem } from './PopoverLinkItem';
import { useLocalStorage } from '../../utils/customHooks';
import { useState } from 'react';
import { GroupsInfoDialog } from './GroupsInfoDialog';

export const InfoButton = () => {
    const [groupsInfoDialogSeen, setGroupsInfoDialogSeen] = useLocalStorage('groupsInfoDialogSeen', false);
    const [showGroupsInfoDialog, setShowGroupsInfoDialog] = useState<boolean>(false);

    const onGroupsInfoClose = () => {
        setShowGroupsInfoDialog(false);
        setGroupsInfoDialogSeen(true);
    };

    const showGroupsInfo = (event: React.MouseEvent) => {
        event.preventDefault();
        setShowGroupsInfoDialog(true);
    };

    return (
        <>
            <ActionBarItem id={'serviceInfo'} className={'myItem'}>
                <ActionBarItem.Icon>
                    <span
                        className={'icon rioglyph rioglyph-info-sign'}
                        data-cy={'action-bar-info-button'}
                        data-testid={'action-bar-info-button'}
                    />
                </ActionBarItem.Icon>
                <ActionBarItem.Popover className={'myItemPopover'} title={<FormattedMessage id={'tags.tabTitle'} />}>
                    <ul className={'ServiceInfoPopoverContent list-unstyled'}>
                        <PopoverLinkItem
                            icon={'rioglyph-question-sign'}
                            messageId={'tags.infoButtonPopover.items.groupsInfo'}
                            onClick={showGroupsInfo}
                        />
                    </ul>
                </ActionBarItem.Popover>
            </ActionBarItem>
            <GroupsInfoDialog show={!groupsInfoDialogSeen || showGroupsInfoDialog} onClose={onGroupsInfoClose} />
        </>
    );
};
