import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import langReducer from '../lang/langSlice';
import { appReducer } from '../../features/app/App.reducer';
import loginReducer from '../login/loginSlice';
import tokenHandlingReducer from '../tokenHandling/tokenHandlingSlice';
import { tagReducer } from '../../features/app/tags/Tags.reducer';
import { tagCreationDialogReducer } from '../../features/app/tags/create/TagCreationDialog.reducer';
import { tagListToolbarReducer } from '../../features/app/tags/list/toolbar/TagListToolbar.reducer';

export const rootReducer = combineReducers({
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenHandlingReducer,
    tags: tagReducer,
    tagCreationDialog: tagCreationDialogReducer,
    tagsTableToolbar: tagListToolbarReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
