function debugCookieExists() {
    return document.cookie.indexOf('debug=true') >= 0;
}

export const trace =
    import.meta.env.DEV || debugCookieExists()
        ? (...args: any) => {
              console.log('[debug]', ...args);
          }
        : () => {};
