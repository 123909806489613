import { connect } from 'react-redux';
import { TagListDetailsFooter } from './TagListDetailsFooter';
import { deleteTags, fetchETag, updateTag } from '../../Tags.actions';
import { AccessToken } from '../../../../../configuration';
import { getSelectedTag, getSelectedTagVersion, getTagUpdating } from '../../Tags.selector';
import { Tag } from '../../types';
import { IntlShape } from 'react-intl';
import { RootState } from '../../../../../configuration/setup/store';
import { TagThunkDispatch } from '../../Tags.types';
import { getAccessToken } from '../../../../../configuration/tokenHandling/tokenHandlingSlice';

export interface TagListDetailsFooterPropertiesFromState {
    accessToken: AccessToken;
    tagUpdating: boolean;
    selectedTag: Tag | null;
    selectedTagVersion: string | null;
}

export interface TagListDetailsFooterPropertiesFromDispatch {
    deleteTag: (accessToken: AccessToken, tagIds: string, intl: IntlShape) => void;
    updateTag: (accessToken: AccessToken, updatedTag: Tag, tagVersion: string, intl: IntlShape) => void;
    fetchETag: (accessToken: AccessToken, tagId: string) => void;
}

function mapStateToProps(state: RootState): TagListDetailsFooterPropertiesFromState {
    return {
        accessToken: getAccessToken(state),
        tagUpdating: getTagUpdating(state),
        selectedTag: getSelectedTag(state),
        selectedTagVersion: getSelectedTagVersion(state),
    };
}

function mapDispatchToProps(dispatch: TagThunkDispatch): TagListDetailsFooterPropertiesFromDispatch {
    return {
        deleteTag: (accessToken: AccessToken, tagId: string, intl: IntlShape) =>
            dispatch(deleteTags(accessToken, [tagId], intl)),
        updateTag: (accessToken: AccessToken, updatedTag: Tag, tagVersion: string, intl: IntlShape) =>
            dispatch(updateTag(accessToken, updatedTag, tagVersion, intl)),
        fetchETag: (accessToken: AccessToken, tagId: string) => dispatch(fetchETag(accessToken, tagId)),
    };
}

const TagListDetailsFooterContainer = connect(mapStateToProps, mapDispatchToProps)(TagListDetailsFooter);

export default TagListDetailsFooterContainer;
