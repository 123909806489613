import React, { useEffect, useState } from 'react';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { Tag } from '../types';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { TagListPropertiesFromDispatch, TagListPropertiesFromState } from './TagList.container';
import { useNavigate } from 'react-router-dom';
import keysIn from 'lodash/keysIn';
import mapValues from 'lodash/mapValues';

export interface TableColumnDetails {
    width: number;
    defaultWidth: number;
    maxWidth: number;
    label: string;
}

export interface TagListOwnProps {
    toggleDiscardChangesDialog: (opened: boolean, rowId: string | null) => void;
}

type TagListProperties = TagListPropertiesFromDispatch & TagListPropertiesFromState & TagListOwnProps;

export const TagList = (props: TagListProperties) => {
    const {
        tags,
        assignedAssets,
        assignedDrivers,
        tableViewType,
        assignedAssetsLoaded,
        assignedDriversLoaded,
        deleteTags,
        accessToken,
        tagsDeletionInProgress,
        selectedTag,
        unsavedTagChanges,
        toggleDiscardChangesDialog,
    } = props;
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!tagsDeletionInProgress) {
            setShowDeleteDialog(false);
        }
    }, [tagsDeletionInProgress]);

    const getRowClassNames = (tagId: string) => {
        return classNames(selectedTag?.id === tagId && 'active');
    };

    const renderTableCaption = (column: string, columnDetails: TableColumnDetails) => {
        const style =
            columnDetails && columnDetails.width
                ? {
                      minWidth: columnDetails.width,
                      width: columnDetails.width,
                  }
                : {};

        return <col key={column} style={style} />;
    };

    const renderTableHead = (column: string, label: string) => {
        const tableHeadClassNames = classNames('user-select-none ');

        return (
            <FormattedMessage key={column} id={label}>
                {(translation) => (
                    <th key={column} className={tableHeadClassNames}>
                        <span>{translation}</span>
                    </th>
                )}
            </FormattedMessage>
        );
    };

    const renderColumnValue = (column: string, tag: Tag) => {
        switch (column) {
            case 'name': {
                return tag.name;
            }
            case 'assignedAssets': {
                return assignedAssetsLoaded ? (assignedAssets[tag.id]?.length ?? 0) : '...';
            }
            case 'assignedDrivers': {
                return assignedDriversLoaded ? (assignedDrivers[tag.id]?.length ?? 0) : '...';
            }
            default:
                throw Error('column from ColumnConfig not handled in renderColumnValue - please add it');
        }
    };

    const getColumnConfig: () => { [key: string]: TableColumnDetails } = () => {
        return {
            name: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'tags.tagList.columnTitle.name',
            },
            assignedAssets: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'tags.tagList.columnTitle.assignedAssets',
            },
            assignedDrivers: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'tags.tagList.columnTitle.assignedDrivers',
            },
        };
    };

    const columnsDetails = getColumnConfig();
    const columns = keysIn(columnsDetails);
    const columnLabels = mapValues(columnsDetails, (column) => column.label);
    const rows: Tag[] = tags;

    const handleToggleAll = (shouldSelect: boolean) => {
        const allRowIds = rows.map((tag) => tag.id);

        if (shouldSelect) {
            setSelectedTagIds(allRowIds);
        } else {
            setSelectedTagIds([]);
        }
    };

    const toggleTagIdSelection = (tagId: string) => {
        const newSelectedTagIds = selectedTagIds.includes(tagId)
            ? selectedTagIds.filter((selectedTagId) => selectedTagId !== tagId)
            : selectedTagIds.concat(tagId);

        setSelectedTagIds(newSelectedTagIds);
    };

    const abortDelete = () => setShowDeleteDialog(false);

    const intl = useIntl();

    const confirmDelete = () => {
        deleteTags(accessToken, selectedTagIds, intl);
        setSelectedTagIds([]);
    };

    const isTargetCheckbox = (event: React.MouseEvent) => {
        const target = event.target as Element;
        const classlistValue = target.classList.value;
        return classlistValue === 'checkbox-text' || classlistValue === 'checkbox';
    };

    const handleRowClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const rowId = event.currentTarget.getAttribute('data-key');
        if (unsavedTagChanges) {
            toggleDiscardChangesDialog(true, rowId);
        } else {
            if (isTargetCheckbox(event) && rowId != null) {
                toggleTagIdSelection(rowId);
            } else {
                navigate({ pathname: `/tags/${rowId}` });
            }
        }
    };

    const batchButton = (
        <ButtonDropdown
            id={'multi-select-dropdown'}
            title={<span className="rioglyph rioglyph rioglyph-checkboxes" />}
            className={'btn-s'}
            bsStyle={'link' as any}
            iconOnly={true}
            items={[
                {
                    value: (
                        <div data-cy="tags-table-multiselect-delete-button">
                            <span className="rioglyph rioglyph-trash margin-right-10 text-size-18" />
                            <FormattedMessage id={'tags.tagList.deleteSelected'} />
                        </div>
                    ),
                    disabled: selectedTagIds.length === 0,
                    onSelect: () => setShowDeleteDialog(true),
                },
                {
                    divider: true,
                },
                {
                    value: (
                        <div>
                            <FormattedMessage id={'tags.tagList.selectAll'} />
                        </div>
                    ),
                    onSelect: () => handleToggleAll(true),
                },
                {
                    value: (
                        <div>
                            <FormattedMessage id={'tags.tagList.deselectAll'} />
                        </div>
                    ),
                    onSelect: () => handleToggleAll(false),
                },
            ]}
        />
    );

    const tableClassNames = classNames(
        'table',
        'table-head-filled',
        'table-sticky',
        'table-bordered',
        'table-column-overflow-hidden',
        tableViewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        tableViewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards'
    );

    return (
        <>
            <div id="TagsTable">
                {rows.length ? (
                    <table className={tableClassNames}>
                        <colgroup>
                            <col className="table-checkbox" />
                            {columns.map((column) => renderTableCaption(column, columnsDetails[column]))}
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="table-checkbox" data-cy="tags-table-multiselect-button">
                                    {batchButton}
                                </th>
                                {columns.map((column) => renderTableHead(column, columnLabels[column]))}
                            </tr>
                        </thead>
                        <tbody aria-label={'fleet group table content'}>
                            {rows.map((row) => (
                                <tr
                                    key={row.id}
                                    data-key={row.id}
                                    onClick={handleRowClick}
                                    className={getRowClassNames(row.id)}
                                    data-cy="tags-table-row"
                                >
                                    <td className="table-checkbox" data-cy="tags-table-row-check-box">
                                        <Checkbox checked={selectedTagIds.includes(row.id)} />
                                    </td>
                                    {columns.map((col, index) => (
                                        <FormattedMessage key={index} id={columnLabels[col]}>
                                            {(label) => (
                                                <td key={col} data-field={label}>
                                                    <span>{renderColumnValue(col, row)}</span>
                                                </td>
                                            )}
                                        </FormattedMessage>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className={'margin-top-15pct'}>
                        <FormattedMessage id={'tags.noTagsFound'}>
                            {(notFoundMsg) => <NotFoundState headline={notFoundMsg} message="" />}
                        </FormattedMessage>
                    </div>
                )}
            </div>
            <div data-cy="tags-delete-confirmation-dialog">
                <ConfirmationDialog
                    show={showDeleteDialog}
                    title={<FormattedMessage id={'tags.tagList.deleteConfirmationDialog.title'} />}
                    bsSize={'sm'}
                    content={<FormattedMessage id={'tags.tagList.deleteConfirmationDialog.content'} />}
                    onClickConfirm={confirmDelete}
                    onClickCancel={abortDelete}
                    cancelButtonText={<FormattedMessage id={'tags.tagList.deleteConfirmationDialog.button.cancel'} />}
                    confirmButtonText={<FormattedMessage id={'tags.tagList.deleteConfirmationDialog.button.confirm'} />}
                    useOverflow={true}
                    disableConfirm={tagsDeletionInProgress}
                />
            </div>
        </>
    );
};
