import { connect } from 'react-redux';
import { Tags } from './Tags';
import { getFetching, getSelectedTag, getTags } from './Tags.selector';
import { fetchAssets, fetchDrivers, fetchTags, setUnsavedChanges, tagTableRowSelected } from './Tags.actions';
import { TagRowSelectedAction, TagThunkDispatch } from './Tags.types';
import { AccessToken } from '../../../configuration';
import { Tag } from './types';
import { RootState } from '../../../configuration/setup/store';
import { getAccessToken } from '../../../configuration/tokenHandling/tokenHandlingSlice';
import { getUserAccount } from '../../../configuration/login/loginSlice';

export interface TagsPropertiesFromState {
    fetching: boolean;
    accessToken: AccessToken;
    accountId: string | null;
    tags: Tag[];
    selectedTag: Tag | null;
}

export interface TagsPropertiesFromDispatch {
    fetchTags: (accessToken: AccessToken, accountId: string | null) => void;
    fetchAssets: (accessToken: AccessToken) => void;
    fetchDrivers: (accessToken: AccessToken) => void;
    selectRow: (tagId: string | null) => TagRowSelectedAction;
    setUnsavedTagChanges: (newValue: boolean) => void;
}

function mapDispatchToProps(dispatch: TagThunkDispatch): TagsPropertiesFromDispatch {
    return {
        fetchTags: (accessToken: AccessToken, accountId: string | null) => dispatch(fetchTags(accessToken, accountId)),
        fetchAssets: (accessToken: AccessToken) => dispatch(fetchAssets(accessToken)),
        fetchDrivers: (accessToken: AccessToken) => dispatch(fetchDrivers(accessToken)),
        selectRow: (tagId: string | null) => dispatch(tagTableRowSelected(tagId)),
        setUnsavedTagChanges: (newValue: boolean) => dispatch(setUnsavedChanges(newValue)),
    };
}

function mapStateToProps(state: RootState): TagsPropertiesFromState {
    return {
        fetching: getFetching(state),
        accessToken: getAccessToken(state),
        accountId: getUserAccount(state) ?? null,
        tags: getTags(state),
        selectedTag: getSelectedTag(state),
    };
}

const TagsContainer = connect(mapStateToProps, mapDispatchToProps)(Tags);

export default TagsContainer;
