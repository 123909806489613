import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IdToken, LanguageDataInterface } from '../../configuration';
import { config } from '../../config';
import App from './App';
import { hideSessionExpiredDialog } from './App.actions';
import { getSessionExpiredAcknowledged } from './App.selector';
import { RootState } from '../../configuration/setup/store';
import { isUserSessionExpired } from '../../configuration/login/loginSlice';
import { getIdToken } from '../../configuration/tokenHandling/tokenHandlingSlice';
import { getLanguageData, getLocale } from '../../configuration/lang/langSlice';

export interface AppPropertiesFromDispatch {
    hideSessionDialog: () => void;
}

export interface AppPropertiesFromState {
    idToken: IdToken;
    homeRoute: string;
    languageData: LanguageDataInterface;
    showSessionExpired: boolean;
    userLocale: string;
}

const mapDispatchToProps = (dispatch: Dispatch): AppPropertiesFromDispatch => {
    return {
        hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
    };
};

const mapStateToProps = (state: RootState): AppPropertiesFromState => {
    return {
        idToken: getIdToken(state)!,
        homeRoute: config.homeRoute as string,
        languageData: getLanguageData(state),
        showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
        userLocale: getLocale(state) || 'en-GB',
    };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
