import { connect } from 'react-redux';
import { setUnsavedChanges } from '../Tags.actions';
import { TagListDetails } from './TagListDetails';
import { Tag } from '../types';
import { getSelectedTag, getTagUpdateSuccessful, getUnsavedChanges } from '../Tags.selector';
import { RootState } from '../../../../configuration/setup/store';
import { TagThunkDispatch } from '../Tags.types';

export interface TagListDetailsPropertiesFromState {
    selectedTag: Tag | null;
    tagUpdateSuccessful: boolean;
    unsavedChanges: boolean;
}

export interface TagListDetailsPropertiesFromDispatch {
    setUnsavedChanges: (newValue: boolean) => void;
}

function mapStateToProps(state: RootState): TagListDetailsPropertiesFromState {
    return {
        tagUpdateSuccessful: getTagUpdateSuccessful(state),
        selectedTag: getSelectedTag(state),
        unsavedChanges: getUnsavedChanges(state),
    };
}

function mapDispatchToProps(dispatch: TagThunkDispatch): TagListDetailsPropertiesFromDispatch {
    return {
        setUnsavedChanges: (newValue: boolean) => dispatch(setUnsavedChanges(newValue)),
    };
}

const TagListDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(TagListDetails);

export default TagListDetailsContainer;
