import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { TagListToolbarActions, TAGS_TABLE_VIEW_TYPE_CHANGED } from './TagListToolbar.actions';

export interface TagListToolbarState {
    tableViewType: string;
    isTagCreationDialogOpen: boolean;
}

export const INITIAL_TABLE_TOOLBAR_STATE: TagListToolbarState = {
    tableViewType: TableViewToggles.VIEW_TYPE_TABLE,
    isTagCreationDialogOpen: false,
};

export const tagListToolbarReducer = (
    state: TagListToolbarState = INITIAL_TABLE_TOOLBAR_STATE,
    action: TagListToolbarActions
): TagListToolbarState => {
    switch (action.type) {
        case TAGS_TABLE_VIEW_TYPE_CHANGED:
            return {
                ...state,
                tableViewType: action.payload,
            };
        default:
            return state;
    }
};
