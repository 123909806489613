import {
    TagCreateDialogPropertiesFromDispatch,
    TagCreateDialogPropertiesFromState,
} from './TagCreationDialog.container';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import React, { useEffect, useState } from 'react';
import { FormInputField } from '../details/FormInputField';
import { FormattedMessage, useIntl } from 'react-intl';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { PageView } from '../../utils/googleTagManager';
import { gtmUtil } from '../../../../index';
import { fleetGroupsContent } from '../groups-info/GroupsInfo';
import { useUpdateEffect } from '../../utils/customHooks';
import { useNavigate } from 'react-router-dom';

type TagCreateDialogProperties = TagCreateDialogPropertiesFromState & TagCreateDialogPropertiesFromDispatch;

export const TagCreationDialog = (props: TagCreateDialogProperties) => {
    const { show, tagCreating, accessToken, idToken, onHide, createTag, selectedTag, tagCreationSuccessful } = props;
    const [currentTagName, setCurrentTagName] = useState<string>('');
    const navigate = useNavigate();

    const intl = useIntl();

    useUpdateEffect(() => {
        if (show) {
            gtmUtil.triggerPageView(PageView.createDialog);
        } else {
            if (!tagCreationSuccessful) {
                if (selectedTag === null) {
                    gtmUtil.triggerPageView(PageView.overview);
                } else {
                    gtmUtil.triggerPageView(PageView.details);
                }
            }
        }
    }, [show]);

    useEffect(() => {
        if (!show) {
            setCurrentTagName('');
        }
    }, [show]);

    const onTagNameValueChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newName = e.currentTarget.value;
        setCurrentTagName(newName);
    };

    const saveButtonDisabled = () => {
        return currentTagName.trim().length === 0 || tagCreating;
    };

    const onSaveButtonClick = () => {
        if (currentTagName.trim().length !== 0) {
            createTag(accessToken, idToken.account, currentTagName.trim(), navigate, intl);
        } else {
            reportErrorToSentry(new Error('Could not update tag because the selected Tag or version is undefined'));
        }
    };

    const title = <FormattedMessage id={'tags.tagCreationDialog.title'} />;
    const body = (
        <div>
            {fleetGroupsContent()}
            <FormInputField
                translationId={'tags.tagCreationDialog.tagName'}
                disabled={false}
                currentValue={currentTagName}
                onValueChange={onTagNameValueChange}
                withCopyButton={false}
                maxLength={100}
                autoFocus={true}
            />
        </div>
    );

    const footer = (
        <div className="float-right btn-toolbar">
            <button
                type="button"
                className="btn btn-primary"
                onClick={onSaveButtonClick}
                disabled={saveButtonDisabled()}
            >
                <FormattedMessage id={'tags.tagCreationDialog.submit'} />
            </button>
        </div>
    );

    return (
        <div id={'TagCreationDialog'}>
            <Dialog
                show={show}
                title={title}
                body={body}
                footer={footer}
                bsSize={Dialog.SIZE_SM}
                onHide={onHide}
                showCloseButton={true}
            />
        </div>
    );
};
