import { AccessToken } from '../../../configuration';
import { config } from '../../../config';
import { Asset, AssetWithEmbeddedTags, Driver, DriverWithEmbeddedTags, Tag } from './types';
import { IntlShape } from 'react-intl';
import { closableSuccessNotification, nonVanishingErrorNotification } from '../ClickableNotifications';
import {
    getTagsDeletionLastFailureTagName,
    getTagsDeletionLastSuccessTagName,
    getTagUpdateSuccessful,
} from './Tags.selector';
import { ERROR_CODE_GENERIC, ERROR_CODE_NAME_ALREADY_IN_USE } from './details/TagListDetails';
import {
    ETAG_LOADED,
    ETAG_LOADED_FAILED,
    TAG_DELETION_FAILURE,
    TAG_DELETION_SUCCESS,
    TAG_SET_UNSAVED_CHANGES,
    TAG_UPDATE_FAILED,
    TAG_UPDATE_FINISHED,
    TAG_UPDATE_STARTED,
    TAG_UPDATE_SUCCESSFUL,
    TagRowSelectedAction,
    TAGS_ASSIGNED_ASSETS_LOADED,
    TAGS_ASSIGNED_ASSETS_LOADING_FAILED,
    TAGS_ASSIGNED_DRIVERS_LOADED,
    TAGS_ASSIGNED_DRIVERS_LOADING_FAILED,
    TAGS_DELETION_FINISHED,
    TAGS_DELETION_START,
    TAGS_LOADED,
    TAGS_LOADED_FAILED,
    TAGS_ROW_SELECTED,
    TAGS_START_LOADING,
    TagSetUnsavedChanges,
    TagThunkAction,
    TagThunkDispatch,
} from './Tags.types';

export function fetchTags(accessToken: AccessToken, accountId: string | null): TagThunkAction<Promise<void>> {
    return async (dispatch: TagThunkDispatch) => {
        dispatch({
            type: TAGS_START_LOADING,
        });
        const url = new URL(`${config.backend.tagService}/tags`);

        if (null !== accountId) {
            url.search = new URLSearchParams({ account_id: accountId }).toString();
        }

        const tagsEndpoint = `${url.toString()}`;
        const response = await fetch(tagsEndpoint, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const contentType = response.headers.get('content-type');

        if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
            const json = await response.json();
            dispatch({
                type: TAGS_LOADED,
                payload: json.items,
            });
        } else {
            dispatch({
                type: TAGS_LOADED_FAILED,
            });
        }
    };
}

export function fetchETag(accessToken: AccessToken, tagId: string): TagThunkAction<Promise<void>> {
    return async (dispatch: TagThunkDispatch) => {
        const url = new URL(`${config.backend.tagService}/tags/${tagId}`);

        const tagsEndpoint = `${url.toString()}`;
        await fetch(tagsEndpoint, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const contentType = response.headers.get('content-type');
            const etag = response.headers.get('etag');

            if (response.status === 200 && contentType && contentType.includes('application/json') && etag !== null) {
                dispatch({
                    type: ETAG_LOADED,
                    payload: etag,
                });
            } else {
                dispatch({
                    type: ETAG_LOADED_FAILED,
                });
            }
        });
    };
}

export function fetchAssets(accessToken: AccessToken): TagThunkAction<Promise<void>> {
    return async (dispatch: TagThunkDispatch) => {
        const assetsEndpoint = `${config.backend.assetService}/assets?embed=(tags)`;
        let loadedAssets: Asset[] = [];
        let nextLink: string | undefined = assetsEndpoint;
        let success: boolean = true;
        while (nextLink) {
            const response: Response = await fetch(nextLink, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const contentType = response.headers.get('content-type');

            if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
                const json = await response.json();
                const assetsWithTags: AssetWithEmbeddedTags[] = json.items;
                const nextAssets: Asset[] = assetsWithTags.map((assetWithTag) => {
                    return {
                        id: assetWithTag.id,
                        accountId: assetWithTag.account_id,
                        name: assetWithTag.name,
                        identification: assetWithTag.identification,
                        identificationType: assetWithTag.identification_type,
                        type: assetWithTag.type,
                        status: assetWithTag.status,
                        brand: assetWithTag.brand,
                        tagIds: assetWithTag?._embedded?.tags?.items?.map((item: { id: string }) => item.id) ?? [],
                    };
                });
                loadedAssets = [...loadedAssets, ...nextAssets];
                nextLink = json._links?.next?.href;
            } else {
                success = false;
                nextLink = undefined;
            }
        }
        if (success) {
            dispatch({
                type: TAGS_ASSIGNED_ASSETS_LOADED,
                payload: loadedAssets,
            });
        } else {
            dispatch({
                type: TAGS_ASSIGNED_ASSETS_LOADING_FAILED,
            });
        }
    };
}

export function fetchDrivers(accessToken: AccessToken): TagThunkAction<Promise<void>> {
    return async (dispatch: TagThunkDispatch) => {
        const driverServiceEndpoint = `${config.backend.driverService}/drivers?embed=(tags)`;
        let loadedDrivers: Driver[] = [];
        let nextLink: string | undefined = driverServiceEndpoint;
        let success: boolean = true;
        while (nextLink) {
            const response: Response = await fetch(nextLink, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const contentType = response.headers.get('content-type');
            if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
                const json = await response.json();
                const driversWithEmbeddedTags: DriverWithEmbeddedTags[] = json.items;
                const nextDrivers: Driver[] = driversWithEmbeddedTags.map(
                    (driverWithEmbeddedTags: DriverWithEmbeddedTags) => ({
                        id: driverWithEmbeddedTags.id,
                        accountId: driverWithEmbeddedTags.account_id,
                        displayName: driverWithEmbeddedTags.display_name,
                        firstName: driverWithEmbeddedTags.first_name,
                        lastName: driverWithEmbeddedTags.last_name,
                        status: driverWithEmbeddedTags.status,
                        tagIds: driverWithEmbeddedTags._embedded?.tags.map((tag: { id: string }) => tag.id) ?? [],
                    })
                );
                loadedDrivers = [...loadedDrivers, ...nextDrivers];
                nextLink = json._links?.next?.href;
            } else {
                success = false;
                nextLink = undefined;
            }
        }
        if (success) {
            dispatch({
                type: TAGS_ASSIGNED_DRIVERS_LOADED,
                payload: loadedDrivers,
            });
        } else {
            dispatch({
                type: TAGS_ASSIGNED_DRIVERS_LOADING_FAILED,
            });
        }
    };
}

export function deleteTags(accessToken: AccessToken, tagIds: string[], intl: IntlShape): TagThunkAction<Promise<void>> {
    return async (dispatch: TagThunkDispatch, getState) => {
        dispatch({
            type: TAGS_DELETION_START,
        });

        for (const tagId of tagIds) {
            const url = new URL(`${config.backend.tagService}/tags/${tagId}`);
            const tagsEndpoint = `${url.toString()}`;

            const response = await fetch(tagsEndpoint, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'If-None-Match': '*',
                },
            });
            if (response.ok) {
                dispatch({
                    type: TAG_DELETION_SUCCESS,
                    payload: tagId,
                });
                const lastSuccessTagName = getTagsDeletionLastSuccessTagName(getState() as any);
                if (lastSuccessTagName !== null) {
                    closableSuccessNotification(
                        intl.formatMessage({ id: 'tags.tagList.delete.success' }, { tagName: lastSuccessTagName }),
                        'success-notification-tag-deleted'
                    );
                }
            } else {
                dispatch({
                    type: TAG_DELETION_FAILURE,
                    payload: tagId,
                });
                const lastFailureTagName = getTagsDeletionLastFailureTagName(getState() as any);
                if (lastFailureTagName !== null) {
                    nonVanishingErrorNotification(
                        intl.formatMessage({ id: 'tags.tagList.delete.failure' }, { tagName: lastFailureTagName }),
                        'T01'
                    );
                }
            }
        }

        dispatch({
            type: TAGS_DELETION_FINISHED,
        });
    };
}

export function updateTag(
    accessToken: AccessToken,
    tag: Tag,
    tagVersion: string,
    intl: IntlShape
): TagThunkAction<Promise<void>> {
    const url = new URL(`${config.backend.tagService}/tags/${tag.id}`);
    return async (dispatch: TagThunkDispatch, getState) => {
        dispatch({
            type: TAG_UPDATE_STARTED,
        });
        const tagsEndpoint = `${url.toString()}`;
        const response = await fetch(tagsEndpoint, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'If-Match': tagVersion,
            },
            body: JSON.stringify(tag),
        });
        if (response.ok) {
            dispatch({
                type: TAG_UPDATE_SUCCESSFUL,
                payload: {
                    tagId: tag.id,
                    name: tag.name,
                },
            });
            dispatch({
                type: TAG_SET_UNSAVED_CHANGES,
                payload: false,
            });
            const tagUpdateSuccessful = getTagUpdateSuccessful(getState() as any); // TODO improve any cast
            if (tagUpdateSuccessful) {
                closableSuccessNotification(
                    intl.formatMessage({ id: 'tags.tagDetails.tagUpdate.success' }),
                    'success-notification-tag-updated'
                );
            }
        } else {
            let errorCode = null;

            if ([400, 404, 409].includes(response.status)) {
                const body: { title: string; status: number; detail: string } = await response.json();

                if (body.detail) {
                    const errorCodeRegex = new RegExp('^\\[(.+)]:.*$');
                    const matches = errorCodeRegex.exec(body.detail);
                    errorCode = matches && matches.length === 2 ? matches[1] : null;
                }
            } else if ([401, 403].includes(response.status)) {
                errorCode = 'UNAUTHORIZED';
            }

            dispatch({
                type: TAG_UPDATE_FAILED,
                payload: { errorCode },
            });
            const messageId =
                errorCode === ERROR_CODE_NAME_ALREADY_IN_USE
                    ? 'tags.tagDetails.tagUpdate.failure.invalidTagName'
                    : 'tags.tagDetails.tagUpdate.failure';
            nonVanishingErrorNotification(intl.formatMessage({ id: messageId }), errorCode ?? ERROR_CODE_GENERIC);
        }
        dispatch({
            type: TAG_UPDATE_FINISHED,
        });
    };
}

export const setUnsavedChanges = (newValue: boolean): TagSetUnsavedChanges => ({
    type: TAG_SET_UNSAVED_CHANGES,
    payload: newValue,
});

export function tagTableRowSelected(tagId: string | null): TagRowSelectedAction {
    return {
        type: TAGS_ROW_SELECTED,
        payload: tagId,
    };
}
