import { Location } from 'react-router-dom';

export interface WindowWithDataLayer extends Window {
    dataLayer?: object[];
}

export enum PageView {
    details = 'details',
    createDialog = 'create-dialog',
    overview = 'overview',
}

export enum EventType {
    virtPath = 'virtPath',
}

interface VirtPathEvent {
    event: EventType;
    virtPath: PageView;
}

export class GoogleTagManagerUtil {
    lastLocation: Location | null = null;
    lastPageView: PageView | null = null;
    windowWithDataLayer: WindowWithDataLayer;

    constructor(windowWithDataLayer: WindowWithDataLayer) {
        this.windowWithDataLayer = windowWithDataLayer;
    }

    triggerPageView = (pageView: PageView) => {
        this.dataLayerPush({
            event: EventType.virtPath,
            virtPath: pageView,
        });
    };

    dataLayerPush = (event: VirtPathEvent) => {
        const gtmEvent = {
            ...event,
            virtPath: `${event.virtPath}`,
        };
        this.windowWithDataLayer.dataLayer?.push(gtmEvent);
        this.lastPageView = event.virtPath;
    };

    triggerPageViewFromLocation = (location: Location) => {
        if ('/tags' === location.pathname && (null === this.lastLocation || this.lastLocation.pathname !== '/tags')) {
            this.triggerPageView(PageView.overview);
        } else if (location.pathname.startsWith('/tags/') && this.lastLocation?.pathname !== location.pathname) {
            this.triggerPageView(PageView.details);
        }
        return null;
    };
}
