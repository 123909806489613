import { Tag, TagsWithAssignedAssets, TagsWithAssignedDrivers } from './types';
import {
    ETAG_LOADED,
    ETAG_LOADED_FAILED,
    TAG_DELETION_FAILURE,
    TAG_DELETION_SUCCESS,
    TAG_SET_UNSAVED_CHANGES,
    TAG_UPDATE_FAILED,
    TAG_UPDATE_FINISHED,
    TAG_UPDATE_STARTED,
    TAG_UPDATE_SUCCESSFUL,
    TagActions,
    TAGS_ASSIGNED_ASSETS_LOADED,
    TAGS_ASSIGNED_ASSETS_LOADING_FAILED,
    TAGS_ASSIGNED_DRIVERS_LOADED,
    TAGS_ASSIGNED_DRIVERS_LOADING_FAILED,
    TAGS_DELETION_FINISHED,
    TAGS_DELETION_START,
    TAGS_LOADED,
    TAGS_LOADED_FAILED,
    TAGS_ROW_SELECTED,
    TAGS_START_LOADING,
} from './Tags.types';
import { TAG_CREATION_SUCCESSFUL, TagCreationDialogActions } from './create/TagCreationDialog.types';

export interface TagState {
    fetching: boolean;
    tags: Tag[];
    selectedTag: Tag | null;
    selectedTagVersion: string | null;
    assignedAssets: TagsWithAssignedAssets;
    assignedAssetsLoaded: boolean;
    assignedDrivers: TagsWithAssignedDrivers;
    assignedDriversLoaded: boolean;
    tagUpdating: boolean;
    tagUpdateSuccessful: boolean;
    tagUpdateFailed: boolean;
    tagUpdateLastErrorCode: string | null;
    unsavedChanges: boolean;
    deletions: {
        inProgress: boolean;
        lastSuccess: string | null;
        lastFailure: string | null;
    };
}

export const INITIAL_TAGS_STATE: TagState = {
    fetching: false,
    tags: [],
    selectedTag: null,
    selectedTagVersion: null,
    assignedAssets: {},
    assignedAssetsLoaded: false,
    assignedDrivers: {},
    assignedDriversLoaded: false,
    tagUpdating: false,
    tagUpdateSuccessful: false,
    tagUpdateFailed: false,
    tagUpdateLastErrorCode: null,
    unsavedChanges: false,
    deletions: {
        inProgress: false,
        lastSuccess: null,
        lastFailure: null,
    },
};

export const tagReducer = (
    state: TagState = INITIAL_TAGS_STATE,
    action: TagActions | TagCreationDialogActions
): TagState => {
    switch (action.type) {
        case TAGS_START_LOADING:
            return {
                ...state,
                fetching: true,
            };
        case TAGS_LOADED_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case TAGS_LOADED:
            return {
                ...state,
                fetching: false,
                tags: action.payload.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
            };
        case TAGS_ASSIGNED_ASSETS_LOADING_FAILED:
            return {
                ...state,
                assignedAssets: {},
            };
        case TAGS_ASSIGNED_ASSETS_LOADED: {
            let assignedAssets: TagsWithAssignedAssets = {};
            action.payload.forEach((asset) => {
                asset.tagIds.forEach((tagId) => {
                    assignedAssets = {
                        ...assignedAssets,
                        [tagId]: assignedAssets[tagId]?.concat(asset) ?? [asset],
                    };
                });
            });
            return {
                ...state,
                assignedAssetsLoaded: true,
                assignedAssets,
            };
        }
        case TAGS_ASSIGNED_DRIVERS_LOADED: {
            let assignedDrivers: TagsWithAssignedDrivers = {};
            action.payload.forEach((driver) => {
                driver.tagIds.forEach((tagId) => {
                    assignedDrivers = {
                        ...assignedDrivers,
                        [tagId]: assignedDrivers[tagId]?.concat(driver) ?? [driver],
                    };
                });
            });
            return {
                ...state,
                assignedDriversLoaded: true,
                assignedDrivers,
            };
        }
        case TAGS_ASSIGNED_DRIVERS_LOADING_FAILED:
            return {
                ...state,
                assignedDriversLoaded: false,
                assignedDrivers: {},
            };
        case TAGS_DELETION_START:
            return {
                ...state,
                deletions: {
                    ...state.deletions,
                    inProgress: true,
                    lastSuccess: null,
                    lastFailure: null,
                },
            };
        case TAGS_DELETION_FINISHED:
            return {
                ...state,
                deletions: {
                    ...state.deletions,
                    inProgress: false,
                    lastSuccess: null,
                    lastFailure: null,
                },
            };
        case TAG_DELETION_FAILURE: {
            const tagFailedName = state.tags.filter((tag) => action.payload === tag.id)[0]?.name ?? 'unknown';
            return {
                ...state,
                deletions: {
                    ...state.deletions,
                    lastSuccess: null,
                    lastFailure: tagFailedName,
                },
            };
        }
        case TAG_DELETION_SUCCESS: {
            const tagSuccessName = state.tags.filter((tag) => action.payload === tag.id)[0]?.name ?? 'unknown';
            const currentlyAssignedAssets = { ...state.assignedAssets };
            delete currentlyAssignedAssets[action.payload];
            return {
                ...state,
                deletions: {
                    ...state.deletions,
                    lastSuccess: tagSuccessName,
                    lastFailure: null,
                },
                tags: state.tags.filter((tag) => tag.id !== action.payload),
                assignedAssets: currentlyAssignedAssets,
            };
        }
        case TAGS_ROW_SELECTED: {
            const foundTag = state.tags.find((tag) => {
                return tag.id === action.payload;
            });
            return {
                ...state,
                selectedTag: foundTag === undefined ? null : foundTag,
            };
        }
        case TAG_UPDATE_STARTED:
            return {
                ...state,
                tagUpdating: true,
            };
        case TAG_UPDATE_FAILED:
            return {
                ...state,
                tagUpdateLastErrorCode: action.payload.errorCode,
                tagUpdateFailed: true,
            };
        case TAG_UPDATE_FINISHED:
            return {
                ...state,
                tagUpdating: false,
                tagUpdateFailed: false,
                tagUpdateLastErrorCode: null,
                tagUpdateSuccessful: false,
            };
        case TAG_UPDATE_SUCCESSFUL:
            return {
                ...state,
                tags: state.tags.map((tag) => {
                    return tag.id === action.payload.tagId ? { ...tag, name: action.payload.name } : tag;
                }),
                tagUpdateSuccessful: true,
            };
        case ETAG_LOADED:
            return {
                ...state,
                selectedTagVersion: action.payload,
            };
        case ETAG_LOADED_FAILED:
            return {
                ...state,
                selectedTagVersion: null,
            };
        case TAG_CREATION_SUCCESSFUL:
            return {
                ...state,
                tags: [...state.tags, action.payload].sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                ),
            };
        case TAG_SET_UNSAVED_CHANGES:
            return {
                ...state,
                unsavedChanges: action.payload,
            };
        default:
            return state;
    }
};
