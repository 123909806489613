import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { closableSuccessNotification } from '../../ClickableNotifications';

export interface FormInputFieldProperties {
    translationId: string;
    disabled: boolean;
    currentValue: string;
    hasError?: boolean;
    hasFeedback?: boolean;
    feedbackMsg?: React.ReactFragment;
    onValueChange?: (e: React.FormEvent<HTMLInputElement>) => void;
    dataAttribute?: string;
    withCopyButton: boolean;
    hasLengthValidation?: boolean;
    maxLength?: number;
    autoFocus?: boolean;
}

export const FormInputField = (props: FormInputFieldProperties) => {
    const {
        disabled,
        translationId,
        currentValue,
        hasError,
        hasFeedback,
        feedbackMsg,
        onValueChange,
        dataAttribute,
        withCopyButton,
        hasLengthValidation,
        maxLength,
        autoFocus,
    } = props;

    const copiedFeedbackMessage = () => {
        closableSuccessNotification(
            <FormattedMessage id={'tags.tagDetails.copyFeedback'} values={{ tagName: currentValue }} />
        );
    };

    const formGroupClassNames = classNames('form-group', hasError && 'has-error', hasFeedback && 'has-feedback');
    const lengthValidationClassNames = classNames(
        hasError && 'text-color-danger',
        !hasError && 'text-color-highlight',
        'position-absolute',
        'right-10',
        'top-50pct',
        'transform',
        'translate-top-50',
        'text-italic',
        'z-index-3',
        'pointer-events-none'
    );

    return (
        <div className={formGroupClassNames}>
            <label>
                <FormattedMessage id={translationId} />
            </label>
            <div className="input-group">
                <input
                    className="form-control"
                    type="text"
                    readOnly={disabled}
                    value={currentValue ? currentValue : ''}
                    onChange={onValueChange ? onValueChange : () => {}}
                    maxLength={maxLength ? maxLength : -1}
                    data-cy={dataAttribute && dataAttribute}
                    autoFocus={autoFocus ? autoFocus : false}
                />
                {hasLengthValidation && maxLength ? (
                    <div className={lengthValidationClassNames}>
                        {currentValue === '' ? '' : `${currentValue.length}/${maxLength}`}
                    </div>
                ) : (
                    <React.Fragment />
                )}
                {withCopyButton ? (
                    <span className="input-group-btn" onClick={copiedFeedbackMessage}>
                        <CopyToClipboard text={currentValue}>
                            <button className={'btn btn-default ' + (currentValue ? '' : 'disabled')} type="button">
                                <span className="rioglyph rioglyph-duplicate" />
                                <span>Copy</span>
                            </button>
                        </CopyToClipboard>
                    </span>
                ) : (
                    <React.Fragment />
                )}
            </div>
            {hasFeedback && feedbackMsg && <span className={'help-block'}>{feedbackMsg}</span>}
        </div>
    );
};
