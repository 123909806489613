import { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TagListToolbarState } from './TagListToolbar.reducer';

export const TAGS_TABLE_VIEW_TYPE_CHANGED = 'tags/TAGS_TABLE_VIEW_TYPE_CHANGED';

export interface TagsTableViewTypeChangedAction extends Action {
    type: typeof TAGS_TABLE_VIEW_TYPE_CHANGED;
    payload: string;
}

export function tagsTableViewTypeChanges(viewType: TableViewTogglesViewType): TagsTableViewTypeChangedAction {
    return {
        type: TAGS_TABLE_VIEW_TYPE_CHANGED,
        payload: viewType,
    };
}

export type TagListToolbarActions = TagsTableViewTypeChangedAction;

export type TagListToolbarThunkDispatch = ThunkDispatch<TagListToolbarState, undefined, TagListToolbarActions>;
