import { connect } from 'react-redux';
import { TagsWithAssignedAssets, Tag, TagsWithAssignedDrivers } from '../types';
import { deleteTags } from '../Tags.actions';
import {
    getAssignedAssets,
    getAssignedAssetsLoaded,
    getAssignedDrivers,
    getAssignedDriversLoaded,
    getSelectedTag,
    getTags,
    getTagsDeletionInProgress,
    getUnsavedChanges,
} from '../Tags.selector';
import { TagList } from './TagList';
import { getTableViewType } from './toolbar/TagListToolbar.selector';
import { AccessToken } from '../../../../configuration';
import { IntlShape } from 'react-intl';
import { RootState } from '../../../../configuration/setup/store';
import { TagThunkDispatch } from '../Tags.types';
import { getAccessToken } from '../../../../configuration/tokenHandling/tokenHandlingSlice';

export interface TagListPropertiesFromState {
    tableViewType: string;
    tags: Tag[];
    assignedAssets: TagsWithAssignedAssets;
    assignedAssetsLoaded: boolean;
    assignedDrivers: TagsWithAssignedDrivers;
    assignedDriversLoaded: boolean;
    accessToken: AccessToken;
    tagsDeletionInProgress: boolean;
    selectedTag: Tag | null;
    unsavedTagChanges: boolean;
}

export interface TagListPropertiesFromDispatch {
    deleteTags: (accessToken: AccessToken, tagIds: string[], intl: IntlShape) => void;
}

function mapDispatchToProps(dispatch: TagThunkDispatch): TagListPropertiesFromDispatch {
    return {
        deleteTags: (accessToken: AccessToken, tagIds: string[], intl: IntlShape) =>
            dispatch(deleteTags(accessToken, tagIds, intl)),
    };
}

function mapStateToProps(state: RootState): TagListPropertiesFromState {
    return {
        tags: getTags(state),
        tableViewType: getTableViewType(state),
        assignedAssets: getAssignedAssets(state),
        assignedAssetsLoaded: getAssignedAssetsLoaded(state),
        assignedDrivers: getAssignedDrivers(state),
        assignedDriversLoaded: getAssignedDriversLoaded(state),
        accessToken: getAccessToken(state),
        tagsDeletionInProgress: getTagsDeletionInProgress(state),
        selectedTag: getSelectedTag(state),
        unsavedTagChanges: getUnsavedChanges(state),
    };
}

const TagListContainer = connect(mapStateToProps, mapDispatchToProps)(TagList);

export default TagListContainer;
