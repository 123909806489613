import { config } from '../../../../config';
import { v4 as uuidV4 } from 'uuid';
import { Tag, TagType } from '../types';
import { NavigateFunction } from 'react-router-dom';
import { IntlShape } from 'react-intl';
import { ERROR_CODE_GENERIC, ERROR_CODE_NAME_ALREADY_IN_USE } from '../details/TagListDetails';
import { nonVanishingErrorNotification } from '../../ClickableNotifications';
import {
    TAG_CREATION_DIALOG_CLOSE,
    TAG_CREATION_DIALOG_OPEN,
    TAG_CREATION_FAILED,
    TAG_CREATION_FINISHED,
    TAG_CREATION_STARTED,
    TAG_CREATION_SUCCESSFUL,
    TagCreationDialogCloseAction,
    TagCreationDialogOpenAction,
    TagCreationDialogThunkAction,
    TagCreationDialogThunkDispatch,
} from './TagCreationDialog.types';

export function openTagCreationDialog(): TagCreationDialogOpenAction {
    return {
        type: TAG_CREATION_DIALOG_OPEN,
    };
}

export function closeTagCreationDialog(): TagCreationDialogCloseAction {
    return {
        type: TAG_CREATION_DIALOG_CLOSE,
    };
}

export function createTag(
    accessToken: any,
    accountId: string,
    tagName: string,
    navigate: NavigateFunction,
    intl: IntlShape
): TagCreationDialogThunkAction<Promise<void>> {
    const tag: Tag = {
        id: uuidV4(),
        type: TagType.USER,
        name: tagName,
        account_id: accountId,
    };

    const url = new URL(`${config.backend.tagService}/tags/${tag.id}`);
    return async (dispatch: TagCreationDialogThunkDispatch) => {
        dispatch({
            type: TAG_CREATION_STARTED,
        });
        const response = await fetch(url.toString(), {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'If-None-Match': '*',
            },
            body: JSON.stringify(tag),
        });
        if (response.ok) {
            dispatch({
                type: TAG_CREATION_SUCCESSFUL,
                payload: tag,
            });
            navigate({ pathname: `/tags/${tag.id}` });
        } else {
            let errorCode = null;

            if ([400, 404, 409].includes(response.status)) {
                const body: { title: string; status: number; detail: string } = await response.json();

                if (body.detail) {
                    const errorCodeRegex = new RegExp('^\\[(.+)]:.*$');
                    const matches = errorCodeRegex.exec(body.detail);
                    errorCode = matches && matches.length === 2 ? matches[1] : null;
                }
            } else if ([401, 403].includes(response.status)) {
                errorCode = 'UNAUTHORIZED';
            }

            const messageId =
                errorCode === ERROR_CODE_NAME_ALREADY_IN_USE
                    ? 'tags.tagCreationDialog.createFailure.invalidTagName'
                    : 'tags.tagCreationDialog.createFailure';
            nonVanishingErrorNotification(
                intl.formatMessage({ id: messageId }, { tagName: tagName }),
                errorCode ?? ERROR_CODE_GENERIC
            );

            dispatch({
                type: TAG_CREATION_FAILED,
                payload: {
                    errorCode,
                },
            });
        }
        dispatch({
            type: TAG_CREATION_FINISHED,
        });
    };
}
