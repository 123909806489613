import { FormattedMessage, IntlProvider } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import IframeResizer from 'iframe-resizer-react';

import { config } from '../../config';
import TagsContainer from './tags/Tags.container';
import { AppPropertiesFromDispatch, AppPropertiesFromState } from './App.container';
import { InfoButton } from './tags/groups-info/InfoButton';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { DEFAULT_LOCALE } from '../../configuration/lang/lang';

export type AppProperties = AppPropertiesFromDispatch & AppPropertiesFromState;

const App = (props: AppProperties) => {
    const { hideSessionDialog, homeRoute, languageData, showSessionExpired, userLocale } = props;

    const navItems = [
        {
            key: 'assets',
            route: (
                <a href={config.links.assetAdministration as string}>
                    <FormattedMessage id={'tags.tabTitle.assets'} />
                </a>
            ),
        },
        {
            key: 'drivers',
            route: (
                <a href={config.links.driverAdministration as string}>
                    <FormattedMessage id={'tags.tabTitle.drivers'} />
                </a>
            ),
        },
        {
            key: 'tags',
            route: (
                <NavLink to={'/tags'}>
                    <FormattedMessage id={'tags.tabTitle'} />
                </NavLink>
            ),
        },
    ];

    const appTitle = <FormattedMessage id={'tags.moduleName'} />;
    const environment = import.meta.env.PROD ? 'production' : 'local';
    const userMenu = <DefaultUserMenu environment={environment} />;
    const notifications = <RioNotifications />;
    const infoButton = <InfoButton />;
    const menuUrl = config.backend.MENU_SERVICE as string;
    const appNavigator = <IframeResizer className="iFrameResizer" src={menuUrl} />;

    const homeLink = <a href={homeRoute} />;

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={languageData}>
            <ApplicationLayout className={'StarterTemplate'}>
                <ApplicationLayout.Header>
                    <ApplicationHeader
                        label={appTitle}
                        appNavigator={appNavigator}
                        homeRoute={homeLink}
                        navItems={navItems}
                        actionBarItems={[infoButton, notifications, userMenu]}
                    />
                </ApplicationLayout.Header>
                <NotificationsContainer />
                <SessionExpiredDialog locale={userLocale} onClose={hideSessionDialog} show={showSessionExpired} />
                <TagsContainer />
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default App;
