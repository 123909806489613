import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import {
    TagListDetailsFooterPropertiesFromDispatch,
    TagListDetailsFooterPropertiesFromState,
} from './TagListDetailsFooter.container';
import classNames from 'classnames';
import { reportErrorToSentry } from '../../../../../configuration/setup/sentry';

export interface TagListDetailsFooterOwnProps {
    newTagName: string;
}

type TagListDetailsFooterProperties = TagListDetailsFooterPropertiesFromState &
    TagListDetailsFooterPropertiesFromDispatch &
    TagListDetailsFooterOwnProps;

export const TagListDetailsFooter = (props: TagListDetailsFooterProperties) => {
    const { accessToken, deleteTag, selectedTag, selectedTagVersion, updateTag, newTagName, tagUpdating, fetchETag } =
        props;
    const [showDeleteSingleTagDialog, setShowDeleteSingleTagDialog] = useState<boolean>(false);

    const intl = useIntl();

    useEffect(() => {
        if (selectedTag) {
            fetchETag(accessToken, selectedTag.id);
        }
    }, [selectedTag, accessToken, fetchETag]);

    const deleteClick = () => {
        setShowDeleteSingleTagDialog(true);
    };

    const deleteButton = (
        <button
            className="btn btn-danger btn-link"
            type="button"
            disabled={false}
            onClick={deleteClick}
            data-cy="detail-view-delete-button"
        >
            <span className="rioglyph rioglyph-trash text-color-danger margin-right-5" />
            <span>
                <FormattedMessage id={'tags.tagDetails.deleteTagButton'} />
            </span>
        </button>
    );

    const saveButtonDisabled = () => {
        return newTagName.trim().length === 0 || selectedTag?.name === newTagName || tagUpdating;
    };

    const submitButtonClick = () => {
        if (selectedTag !== null && selectedTagVersion !== null) {
            updateTag(accessToken, { ...selectedTag, name: newTagName }, selectedTagVersion, intl);
        } else {
            reportErrorToSentry(new Error('Could not update tag because the selected Tag or version is undefined'));
        }
    };

    const submitButtonClassNames = classNames('btn', 'btn-primary', tagUpdating && 'btn-loading');
    const submitButton = (
        <button
            className={submitButtonClassNames}
            type="button"
            disabled={saveButtonDisabled()}
            onClick={submitButtonClick}
            data-cy="detail-view-submit-button"
        >
            <FormattedMessage id={'tags.tagDetails.submit'} />
        </button>
    );

    const onConfirmDelete = () => {
        if (selectedTag) {
            setShowDeleteSingleTagDialog(false);
            deleteTag(accessToken, selectedTag.id, intl);
        }
    };

    const onCancelDelete = () => {
        setShowDeleteSingleTagDialog(false);
    };

    const tagName = selectedTag?.name ?? '';

    return (
        <React.Fragment>
            <div className="btn-toolbar pull-left">{deleteButton}</div>
            <div className="btn-toolbar pull-right">{submitButton}</div>
            <div data-cy="tags-delete-single-tag-confirmation-dialog">
                <ConfirmationDialog
                    show={showDeleteSingleTagDialog}
                    title={<FormattedMessage id={'tags.tagDetails.deleteConfirmationDialog.title'} />}
                    bsSize={'sm'}
                    content={
                        <FormattedMessage
                            id={'tags.tagDetails.deleteConfirmationDialog.content'}
                            values={{ tagName }}
                        />
                    }
                    onClickConfirm={onConfirmDelete}
                    onClickCancel={onCancelDelete}
                    cancelButtonText={
                        <FormattedMessage id={'tags.tagDetails.deleteConfirmationDialog.button.cancel'} />
                    }
                    confirmButtonText={
                        <FormattedMessage id={'tags.tagDetails.deleteConfirmationDialog.button.confirm'} />
                    }
                    useOverflow={true}
                    disableConfirm={false}
                />
            </div>
        </React.Fragment>
    );
};
