export enum TagType {
    USER = 'user',
}

export interface Tag {
    id: string;
    type: TagType;
    name: string;
    account_id: string;
}

export interface TagsWithAssignedAssets {
    [key: string]: Asset[];
}

export interface TagsWithAssignedDrivers {
    [key: string]: Driver[];
}

export interface Asset {
    id: string;
    accountId: string;
    name: string;
    identification: string | null;
    identificationType: string | null;
    type: string;
    status: string;
    brand: string | null;
    tagIds: string[];
}

export interface AssetWithEmbeddedTags {
    id: string;
    account_id: string;
    name: string;
    identification: string | null;
    identification_type: string | null;
    type: string;
    status: string;
    brand: string | null;
    _embedded?: {
        tags: {
            items: { id: string }[];
        };
    };
}

export interface Driver {
    id: string;
    accountId: string;
    firstName: string | null;
    lastName: string | null;
    displayName: string;
    status: string;
    tagIds: string[];
}

export interface DriverWithEmbeddedTags {
    id: string;
    account_id: string;
    display_name: string;
    first_name: string;
    last_name: string;
    metadata: {
        tenant: string;
        allowed_actions: string[];
    };
    status: string;
    _embedded?: {
        tags: { id: string }[];
    };
    _links: {
        self: {
            href: string;
        };
    };
}
