import { FormattedMessage } from 'react-intl';
import CustomState from '@rio-cloud/rio-uikit/CustomState';

const userGroupsContent = () => {
    return (
        <CustomState
            headline={<FormattedMessage id={'tags.groupsInfo.userGroups.title'} />}
            message={
                <div>
                    <FormattedMessage id={'tags.groupsInfo.userGroups.content'} />{' '}
                    <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://users.iam.rio.cloud/#groups/"
                    >
                        <FormattedMessage id={'tags.groupsInfo.userGroups.link'} />
                    </a>
                    .
                </div>
            }
            icons={[
                {
                    name: 'rioglyph rioglyph-user',
                    className: 'text-size-200pct',
                },
                {
                    name: 'rioglyph rioglyph-user',
                    className: 'text-size-300pct',
                },
                {
                    name: 'rioglyph rioglyph-user',
                    className: 'text-size-200pct',
                },
            ]}
            outerClassName={'margin-bottom-15 bg-highlight-decent'}
            condensed={true}
        />
    );
};

const userGroupRolesContent = () => {
    return (
        <CustomState
            headline={<FormattedMessage id={'tags.groupsInfo.userGroupRoles.title'} />}
            message={
                <div>
                    <FormattedMessage id={'tags.groupsInfo.userGroupRoles.content'} />
                </div>
            }
            icons={[
                {
                    name: 'rioglyph rioglyph-role-management',
                    className: 'text-size-300pct',
                },
            ]}
            outerClassName={'margin-bottom-15 bg-highlight-decent'}
            condensed={true}
        />
    );
};

export const fleetGroupsContent = () => {
    return (
        <CustomState
            headline={<FormattedMessage id={'tags.groupsInfo.fleetGroups.title'} />}
            message={
                <div>
                    <FormattedMessage id={'tags.groupsInfo.fleetGroups.content'} />
                </div>
            }
            icons={[
                {
                    name: 'rioglyph rioglyph-van',
                    className: 'text-size-200pct',
                },
                {
                    name: 'rioglyph rioglyph-truck',
                    className: 'text-size-300pct',
                },
                {
                    name: 'rioglyph rioglyph-driver',
                    className: 'text-size-300pct',
                },
                {
                    name: 'rioglyph rioglyph-trailer',
                    className: 'text-size-200pct',
                },
            ]}
            outerClassName={'margin-bottom-15 bg-highlight-decent'}
            condensed={true}
        />
    );
};

export const groupsInfoContent = () => {
    return (
        <div data-cy={'groups-info-dialog'}>
            {fleetGroupsContent()}
            {userGroupsContent()}
            {userGroupRolesContent()}
        </div>
    );
};
