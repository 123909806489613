import { FormattedMessage } from 'react-intl';

interface PopoverLinkItemProps {
    icon: string;
    messageId: string;
    onClick: (event: React.MouseEvent) => void;
}

export const PopoverLinkItem = (props: PopoverLinkItemProps) => {
    const { icon, messageId, onClick } = props;
    return (
        <li>
            <div className={'display-flex align-items-baseline padding-top-5 padding-bottom-5'}>
                <div className={'display-flex justify-content-center width-20 margin-right-5'}>
                    {icon && <span className={`rioglyph ${icon} text-size-large`} />}
                </div>
                <a
                    className={'display-block cursor-pointer text-decoration-none width-0 flex-1-0'}
                    onClick={onClick}
                    href={'#/'}
                >
                    <span>
                        <FormattedMessage id={messageId} />
                    </span>
                </a>
            </div>
        </li>
    );
};
