import React, { useEffect, useState } from 'react';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { TagListDetailsPropertiesFromDispatch, TagListDetailsPropertiesFromState } from './TagListDetails.container';
import TagListDetailsFooterContainer from './sidebarfooter/TagListDetailsFooter.container';
import { FormInputField } from './FormInputField';
import { useNavigate } from 'react-router-dom';

export interface TagListDetailOwnProps {
    toggleDiscardChangesDialog: (opened: boolean, rowId: string | null) => void;
}

type TagListDetailsProperties = TagListDetailsPropertiesFromDispatch &
    TagListDetailsPropertiesFromState &
    TagListDetailOwnProps;

const SIDEBAR_BREAKPOINT = 1440;
export const ERROR_CODE_NAME_ALREADY_IN_USE = 'T06';
export const ERROR_CODE_GENERIC = 'TXX';

export const TagListDetails = (props: TagListDetailsProperties) => {
    const { selectedTag, toggleDiscardChangesDialog, setUnsavedChanges, unsavedChanges } = props;
    const initialTagName = selectedTag?.name ?? '';
    const [currentTagName, setCurrentTagName] = useState<string>(initialTagName);
    const navigate = useNavigate();

    useEffect(() => {
        const tagName = selectedTag?.name ?? '';
        setCurrentTagName(tagName);
    }, [selectedTag]);

    const onClose = () => {
        if (unsavedChanges) {
            toggleDiscardChangesDialog(true, null);
        } else {
            navigate({ pathname: '/tags' });
        }
    };

    const onTagNameValueChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newName = e.currentTarget.value;
        setUnsavedChanges(newName !== initialTagName);
        setCurrentTagName(newName);
    };

    const sideBarContent = () => (
        <form data-cy="detail-view-form">
            <FormInputField
                translationId={'tags.tagDetails.name'}
                currentValue={currentTagName}
                disabled={false}
                onValueChange={onTagNameValueChange}
                dataAttribute="detail-view-form-name"
                withCopyButton={true}
                maxLength={100}
            />
        </form>
    );

    return (
        <React.Fragment>
            <Sidebar
                title={currentTagName}
                closed={false}
                position={'right'}
                onClose={onClose}
                resizable={true}
                minWidth={300}
                width={400}
                footer={<TagListDetailsFooterContainer newTagName={currentTagName} />}
                switchModeBreakpoint={SIDEBAR_BREAKPOINT}
                titleClassName={'padding-left-10'}
                bodyClassName={'padding-left-20 padding-right-20'}
            >
                {sideBarContent()}
            </Sidebar>
        </React.Fragment>
    );
};
