import { Action } from 'redux';
import { Tag } from '../types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TagCreationDialogState } from './TagCreationDialog.reducer';

export const TAG_CREATION_DIALOG_OPEN = 'tags/TAG_CREATION_DIALOG_OPEN';
export const TAG_CREATION_DIALOG_CLOSE = 'tags/TAG_CREATION_DIALOG_CLOSE';
export const TAG_CREATION_STARTED = 'tags/TAG_CREATION_STARTED';
export const TAG_CREATION_SUCCESSFUL = 'tags/TAG_CREATION_SUCCESSFUL';
export const TAG_CREATION_FAILED = 'tags/TAG_CREATION_FAILED';
export const TAG_CREATION_FINISHED = 'tags/TAG_CREATION_FINISHED';

export interface TagCreationDialogOpenAction extends Action {
    type: typeof TAG_CREATION_DIALOG_OPEN;
}

export interface TagCreationDialogCloseAction extends Action {
    type: typeof TAG_CREATION_DIALOG_CLOSE;
}

export interface TagCreationStartedAction extends Action {
    type: typeof TAG_CREATION_STARTED;
}

export interface TagCreationSuccessfulAction extends Action {
    type: typeof TAG_CREATION_SUCCESSFUL;
    payload: Tag;
}

export interface TagCreationFailedAction extends Action {
    type: typeof TAG_CREATION_FAILED;
    payload: {
        errorCode: string | null;
    };
}

export interface TagCreationFinishedAction extends Action {
    type: typeof TAG_CREATION_FINISHED;
}

export type TagCreationDialogActions =
    | TagCreationDialogOpenAction
    | TagCreationDialogCloseAction
    | TagCreationStartedAction
    | TagCreationSuccessfulAction
    | TagCreationFailedAction
    | TagCreationFinishedAction;

export type TagCreationDialogThunkAction<R> = ThunkAction<
    R,
    TagCreationDialogState,
    undefined,
    TagCreationDialogActions
>;

export type TagCreationDialogThunkDispatch = ThunkDispatch<TagCreationDialogState, undefined, TagCreationDialogActions>;
