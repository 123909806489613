import { TagsWithAssignedAssets, Tag, TagsWithAssignedDrivers } from './types';
import { RootState } from '../../../configuration/setup/store';

export function getFetching(state: RootState): boolean {
    return state.tags.fetching;
}

export function getTags(state: RootState): Tag[] {
    return state.tags.tags;
}

export function getAssignedAssets(state: RootState): TagsWithAssignedAssets {
    return state.tags.assignedAssets;
}

export function getAssignedAssetsLoaded(state: RootState): boolean {
    return state.tags.assignedAssetsLoaded;
}

export function getAssignedDrivers(state: RootState): TagsWithAssignedDrivers {
    return state.tags.assignedDrivers;
}

export function getAssignedDriversLoaded(state: RootState): boolean {
    return state.tags.assignedDriversLoaded;
}

export function getTagsDeletionInProgress(state: RootState): boolean {
    return state.tags.deletions.inProgress;
}

export function getTagsDeletionLastSuccessTagName(state: RootState): string | null {
    return state.tags.deletions.lastSuccess;
}

export function getTagsDeletionLastFailureTagName(state: RootState): string | null {
    return state.tags.deletions.lastFailure;
}

export function getTagUpdating(state: RootState): boolean {
    return state.tags.tagUpdating;
}

export function getTagUpdateSuccessful(state: RootState): boolean {
    return state.tags.tagUpdateSuccessful;
}

export function getTagUpdateFailed(state: RootState): boolean {
    return state.tags.tagUpdateFailed;
}

export function getSelectedTag(state: RootState) {
    return state.tags.selectedTag;
}

export function getSelectedTagVersion(state: RootState) {
    return state.tags.selectedTagVersion;
}

export function getTagUpdateLastErrorCode(state: RootState) {
    return state.tags.tagUpdateLastErrorCode;
}

export const getUnsavedChanges = (state: RootState) => state.tags.unsavedChanges;
