import { Action } from 'redux';
import { Asset, Driver, Tag } from './types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TagState } from './Tags.reducer';

export const TAGS_START_LOADING = 'tags/TAGS_START_LOADING';
export const ETAG_LOADED = 'tags/ETAG_LOADED';
export const ETAG_LOADED_FAILED = 'tags/ETAG_LOADED_FAILED';
export const TAGS_LOADED = 'tags/TAGS_LOADED';
export const TAGS_LOADED_FAILED = 'tags/TAGS_LOADED_FAILED';
export const TAGS_ASSIGNED_ASSETS_LOADED = 'tags/TAGS_ASSIGNED_ASSETS_LOADED';
export const TAGS_ASSIGNED_ASSETS_LOADING_FAILED = 'tags/TAGS_ASSIGNED_ASSETS_LOADING_FAILED';
export const TAGS_ASSIGNED_DRIVERS_LOADED = 'tags/TAGS_ASSIGNED_DRIVERS_LOADED';
export const TAGS_ASSIGNED_DRIVERS_LOADING_FAILED = 'tags/TAGS_ASSIGNED_DRIVERS_LOADING_FAILED';
export const TAGS_DELETION_START = 'tags/TAGS_DELETION_START';
export const TAGS_DELETION_FINISHED = 'tags/TAGS_DELETION_FINISHED';
export const TAG_DELETION_SUCCESS = 'tags/TAG_DELETION_SUCCESS';
export const TAG_DELETION_FAILURE = 'tags/TAG_DELETION_FAILURE';
export const TAGS_ROW_SELECTED = 'tags/TAGS_ROW_SELECTED';
export const TAG_UPDATE_STARTED = 'tags/TAG_UPDATE_STARTED';
export const TAG_UPDATE_SUCCESSFUL = 'tags/TAG_UPDATE_SUCCESSFUL';
export const TAG_UPDATE_FAILED = 'tags/TAG_UPDATE_FAILED';
export const TAG_UPDATE_FINISHED = 'tags/TAG_UPDATE_FINISHED';
export const TAG_SET_UNSAVED_CHANGES = 'tags/TAG_SET_UNSAVED_CHANGES';

export interface TagsStartLoadingAction extends Action {
    type: typeof TAGS_START_LOADING;
}

export interface TagsLoadedAction extends Action {
    type: typeof TAGS_LOADED;
    payload: Tag[];
}

export interface TagsLoadedFailedAction extends Action {
    type: typeof TAGS_LOADED_FAILED;
}

export interface ETagLoadedAction extends Action {
    type: typeof ETAG_LOADED;
    payload: string | null;
}

export interface ETagLoadedFailedAction extends Action {
    type: typeof ETAG_LOADED_FAILED;
}

export interface TagsAssignedAssetsLoadedAction extends Action {
    type: typeof TAGS_ASSIGNED_ASSETS_LOADED;
    payload: Asset[];
}

export interface TagsAssignedAssetsLoadingFailedAction extends Action {
    type: typeof TAGS_ASSIGNED_ASSETS_LOADING_FAILED;
}

export interface TagsAssignedDriversLoadedAction extends Action {
    type: typeof TAGS_ASSIGNED_DRIVERS_LOADED;
    payload: Driver[];
}

export interface TagsAssignedDriversLoadingFailedAction extends Action {
    type: typeof TAGS_ASSIGNED_DRIVERS_LOADING_FAILED;
}

export interface TagsDeletionStartedAction extends Action {
    type: typeof TAGS_DELETION_START;
}

export interface TagsDeletionFinishedAction extends Action {
    type: typeof TAGS_DELETION_FINISHED;
}

export interface TagDeletionSuccessAction extends Action {
    type: typeof TAG_DELETION_SUCCESS;
    payload: string;
}

export interface TagDeletionFailureAction extends Action {
    type: typeof TAG_DELETION_FAILURE;
    payload: string;
}

export interface TagRowSelectedAction extends Action {
    type: typeof TAGS_ROW_SELECTED;
    payload: string | null;
}

export interface TagsDetailViewUpdateStartedAction extends Action {
    type: typeof TAG_UPDATE_STARTED;
}

export interface TagsDetailViewUpdateSuccessfulAction extends Action {
    type: typeof TAG_UPDATE_SUCCESSFUL;
    payload: {
        tagId: string;
        name: string;
    };
}

export interface TagsDetailViewUpdateFailedAction extends Action {
    type: typeof TAG_UPDATE_FAILED;
    payload: {
        errorCode: string | null;
    };
}

export interface TagsDetailViewUpdateFinishedAction extends Action {
    type: typeof TAG_UPDATE_FINISHED;
}

export interface TagSetUnsavedChanges extends Action {
    type: typeof TAG_SET_UNSAVED_CHANGES;
    payload: boolean;
}

export type TagActions =
    | TagsStartLoadingAction
    | TagsLoadedAction
    | ETagLoadedAction
    | TagsLoadedFailedAction
    | ETagLoadedFailedAction
    | TagsAssignedAssetsLoadedAction
    | TagsAssignedAssetsLoadingFailedAction
    | TagsAssignedDriversLoadedAction
    | TagsAssignedDriversLoadingFailedAction
    | TagsDeletionStartedAction
    | TagsDeletionFinishedAction
    | TagDeletionSuccessAction
    | TagDeletionFailureAction
    | TagRowSelectedAction
    | TagsDetailViewUpdateStartedAction
    | TagsDetailViewUpdateSuccessfulAction
    | TagsDetailViewUpdateFailedAction
    | TagsDetailViewUpdateFinishedAction
    | TagSetUnsavedChanges;

export type TagThunkAction<R> = ThunkAction<R, TagState, undefined, TagActions>;

export type TagThunkDispatch = ThunkDispatch<TagState, undefined, TagActions>;
