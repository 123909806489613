import React, { useEffect, useState } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FormattedMessage } from 'react-intl';
import { TagsPropertiesFromDispatch, TagsPropertiesFromState } from './Tags.container';
import TagListContainer from './list/TagList.container';
import TagListToolbarContainer from './list/toolbar/TagListToolbar.container';
import TagListDetailsContainer from './details/TagListDetails.container';
import { useLocation, useNavigate } from 'react-router-dom';

export type TagsProperties = TagsPropertiesFromState & TagsPropertiesFromDispatch;

export const Tags = (props: TagsProperties) => {
    const {
        fetching,
        fetchTags,
        fetchAssets,
        fetchDrivers,
        accountId,
        accessToken,
        tags,
        selectRow,
        selectedTag,
        setUnsavedTagChanges,
    } = props;

    const [newRowId, setNewRowId] = useState<string | null>(null);
    const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        fetchTags(accessToken, accountId);
    }, [accessToken, accountId, fetchTags]);

    useEffect(() => {
        fetchAssets(accessToken);
    }, [accessToken, fetchAssets]);

    useEffect(() => {
        fetchDrivers(accessToken);
    }, [accessToken, fetchDrivers]);

    useEffect(() => {
        const pathWithoutBaseUrl = location.pathname.slice(6);
        if (tags.map((tag) => tag.id).includes(pathWithoutBaseUrl)) {
            selectRow(pathWithoutBaseUrl);
        } else {
            selectRow(null);
        }
    }, [tags, location, selectRow]);

    const confirmDiscardChanges = () => {
        setUnsavedTagChanges(false);
        setShowDiscardChangesDialog(false);
        const pathname = newRowId ? `/tags/${newRowId}` : '/tags';
        navigate({ pathname });
    };

    const toggleDiscardChangesDialog = (opened: boolean, rowId: string | null) => {
        setShowDiscardChangesDialog(opened);
        setNewRowId(rowId);
    };

    const hideConfirmationDialog = () => {
        toggleDiscardChangesDialog(false, null);
    };

    return (
        <React.Fragment>
            <ConfirmationDialog
                show={showDiscardChangesDialog}
                title={<FormattedMessage id={'tags.unsavedChangesDialog.title'} />}
                content={<FormattedMessage id={'tags.unsavedChangesDialog.content'} />}
                onClickConfirm={confirmDiscardChanges}
                onClickCancel={hideConfirmationDialog}
                cancelButtonText={<FormattedMessage id={'tags.unsavedChangesDialog.button.cancel'} />}
                confirmButtonText={<FormattedMessage id={'tags.unsavedChangesDialog.button.confirm'} />}
                disableConfirm={false}
                useOverflow={false}
                bsSize={'sm'}
            />
            {selectedTag && (
                <ApplicationLayout.Sidebar className="right">
                    <TagListDetailsContainer toggleDiscardChangesDialog={toggleDiscardChangesDialog} />
                </ApplicationLayout.Sidebar>
            )}
            <ApplicationLayout.Body className={'responsive'}>
                {fetching ? (
                    <FormattedMessage id={'tags.spinner.loading'}>
                        {(loadingText) => <Spinner text={loadingText} />}
                    </FormattedMessage>
                ) : (
                    <>
                        <TagListToolbarContainer />
                        <TagListContainer toggleDiscardChangesDialog={toggleDiscardChangesDialog} />
                    </>
                )}
            </ApplicationLayout.Body>
        </React.Fragment>
    );
};
