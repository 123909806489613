import { connect } from 'react-redux';
import { TagCreationDialog } from './TagCreationDialog';
import { AccessToken, IdToken } from '../../../../configuration';
import { closeTagCreationDialog, createTag } from './TagCreationDialog.actions';
import {
    getLastErrorCode,
    getShowDialog,
    getTagCreating,
    getTagCreationFailed,
    getTagCreationSuccessful,
} from './TagCreationDialog.selectors';
import { Tag } from '../types';
import { getSelectedTag } from '../Tags.selector';
import { NavigateFunction } from 'react-router-dom';
import { IntlShape } from 'react-intl';
import { RootState } from '../../../../configuration/setup/store';
import { TagCreationDialogCloseAction, TagCreationDialogThunkDispatch } from './TagCreationDialog.types';
import { getAccessToken, getIdToken } from '../../../../configuration/tokenHandling/tokenHandlingSlice';

export interface TagCreateDialogPropertiesFromState {
    show: boolean;
    tagCreating: boolean;
    accessToken: AccessToken;
    idToken: IdToken;
    tagCreationFailed: boolean;
    lastErrorCode: string | null;
    selectedTag: Tag | null;
    tagCreationSuccessful: boolean;
}

export interface TagCreateDialogPropertiesFromDispatch {
    onHide: () => TagCreationDialogCloseAction;
    createTag: (
        accessToken: AccessToken,
        accountId: string,
        tagName: string,
        navigate: NavigateFunction,
        intl: IntlShape
    ) => void;
}

function mapStateToProps(state: RootState): TagCreateDialogPropertiesFromState {
    return {
        show: getShowDialog(state),
        tagCreating: getTagCreating(state),
        accessToken: getAccessToken(state),
        idToken: getIdToken(state)!,
        tagCreationFailed: getTagCreationFailed(state),
        lastErrorCode: getLastErrorCode(state),
        selectedTag: getSelectedTag(state),
        tagCreationSuccessful: getTagCreationSuccessful(state),
    };
}

function mapDispatchToProps(dispatch: TagCreationDialogThunkDispatch): TagCreateDialogPropertiesFromDispatch {
    return {
        onHide: () => dispatch(closeTagCreationDialog()),
        createTag: (
            accessToken: AccessToken,
            accountId: string,
            tagName: string,
            navigate: NavigateFunction,
            intl: IntlShape
        ) => dispatch(createTag(accessToken, accountId, tagName, navigate, intl)),
    };
}

const TagCreationDialogContainer = connect(mapStateToProps, mapDispatchToProps)(TagCreationDialog);

export default TagCreationDialogContainer;
