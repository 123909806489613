export const HIDE_SESSION_EXPIRED_DIALOG = 'app/HIDE_SESSION_EXPIRED_DIALOG';

export interface HideSessionExpiredAction {
    type: typeof HIDE_SESSION_EXPIRED_DIALOG;
}

export type AppActions = HideSessionExpiredAction;

export const hideSessionExpiredDialog = () => {
    return {
        type: HIDE_SESSION_EXPIRED_DIALOG,
    };
};
