import Notification from '@rio-cloud/rio-uikit/Notification';

export function nonVanishingErrorNotification(
    message: string | JSX.Element | JSX.Element[],
    errorCode: string,
    dataAttribute?: string
): any {
    return Notification.error(
        <span data-cy={dataAttribute}>
            {message}
            <hr />
            {'Error Code: ' + errorCode}
            <span className={'notification-close'}>
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        1000 * 60 * 15,
        () => {}
    );
}

export function closableSuccessNotification(message: string | JSX.Element | JSX.Element[], dataAttribute?: string) {
    return Notification.success(
        <span data-cy={dataAttribute}>
            {message}
            <span className={'notification-close'}>
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        1000 * 5,
        () => {}
    );
}
